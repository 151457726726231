<template>
  <div>
    <a-modal
      :visible.sync="show"
      width="960px"
      :title="'退款申请详情（'+formatStatus(infoData.audit_status)+'）'"
      :footer="null"
      @cancel="$emit('update:show', false)"
    >
      <div class="footer" style="margin-top:0;">
        <a-form-model :model="formData"
                      :label-col="labelCol"
                      :wrapper-col="wrapperCol"
                      ref="refundDetailRef">
          <!-- 申请内容 -->
          <a-row>
            <a-col :span="24">
              <a-form-model-item prop :labelCol="{ span: 2 }" label="订单号：">
                <a-button class="p-0" type="link" @click="handlerOrderDetail">{{orderNo}}</a-button>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="24">
              <a-form-model-item prop :labelCol="{ span: 2 }" label="退款理由">
                <span>{{infoData.refund_reason}}</span>
              </a-form-model-item>
            </a-col>
          </a-row>

          <a-row style="margin-bottom: 10px;">
            <a-col :span="24">
              <a-form-model-item prop :labelCol="{ span: 2 }" label="图片说明">
                <img v-for="item in imgList"
                     class="img-class"
                     :src="item.image_url"
                     :key="item.image_url"
                     @click="handlerBigImg(item)" />
              </a-form-model-item>
            </a-col>
          </a-row>

          <!-- 子订单 -->
          <div
            class="mb-3"
            v-for="orderitem in infoData.suborder_list"
            :key="orderitem.suborder_no"
          >
            <div class="flex justify-between mt-3 mb-2">
              <span
                class="text-sm font-bold text-black"
              >{{orderitem.order_type_name}}子订单 {{orderitem.suborder_no}}</span>
              <span class="text-sm text-black">退款 {{orderitem.audit_refund_amount}}元
                <span v-if="Number(orderitem.apply_refund_freight_amount)>0">（含运费{{orderitem.apply_refund_freight_amount}}元）</span>
              </span>
              <!-- <span
                v-if="Number(orderitem.apply_refund_freight_amount)>0"
                class="text-sm text-black"
              >运费{{orderitem.apply_refund_freight_amount}}元</span> -->
            </div>
            <base-table
                rowKey="id"
                :customHeight="300"
                :columnsData="orderitem.isDrink ? columns_drink : columns"
                :tableData="orderitem.goods_list">
            </base-table>
          </div>

          <a-row>
            <a-col :span="24">
              <a-form-model-item :labelCol="{ span: 2 }" prop label="申请金额">
                <span>{{infoData.apply_refund_amount}}元
                  <span v-if="Number(infoData.apply_refund_freight_amount)>0">（含运费{{infoData.apply_refund_freight_amount}}元）</span>
                </span>
              </a-form-model-item>
            </a-col>
          </a-row>

          <!-- 审核内容 -->
          <!-- 金额 -->
          <a-row v-if="infoData.audit_status == 2 || infoData.audit_status == 5">
            <a-col :span="24">
              <a-form-model-item :labelCol="{ span: 2 }" prop label="退款金额">
                <span class="font-bold"
                >{{infoData.audit_refund_amount.toFixed(2)}}元</span>
              </a-form-model-item>
            </a-col>
            <!-- 只有一个订单 -->
            <!-- <a-col :span="24" v-if="infoData.suborder_list.length < 2">
              <a-form-model-item :labelCol="{ span: 2 }" prop label="退款金额">
                <span
                  class="font-bold"
                  v-if="infoData.suborder_list[0].order_type == 1"
                >{{formData.audit_pick_up_order_refund_amount}}元</span>
                <span
                  class="font-bold"
                  v-if="infoData.suborder_list[0].order_type == 2"
                >{{formData.audit_delivery_order_refund_amount}}元</span>
                <span
                  class="font-bold"
                  v-if="infoData.suborder_list[0].order_type == 3"
                >{{formData.audit_express_order_refund_amount}}元</span>
              </a-form-model-item>
            </a-col> -->
            <!-- 多子订单 -->
            <!-- <a-col :span="24" v-else>
              <a-form-model-item :labelCol="{ span: 2 }" prop label="退款金额">
                <span
                  v-for="orderitem in infoData.suborder_list"
                  class="audit_amount_input"
                  :key="orderitem.suborder_no"
                  style="display: inline-block;"
                >
                  <span v-if="orderitem.order_type == 1">
                    {{formData.audit_pick_up_order_refund_amount}}
                    <span class="plus">+</span>
                  </span>
                  <span v-if="orderitem.order_type == 2">
                    {{formData.audit_delivery_order_refund_amount}}
                    <span class="plus">+</span>
                  </span>
                  <span
                    v-if="orderitem.order_type == 3"
                  >{{formData.audit_express_order_refund_amount}}</span>
                </span> =
                <span class="font-bold">{{infoData.audit_refund_amount.toFixed(2)}}元</span>
              </a-form-model-item>
            </a-col> -->
          </a-row>
          <!-- 备注-不可修改（已退款） -->
          <a-row v-if="infoData.audit_status == 5">
            <a-col :span="24">
              <a-form-model-item :labelCol="{ span: 2 }" prop label="审核备注">
                <span>{{infoData.audit_remarks}}</span>
              </a-form-model-item>
            </a-col>
          </a-row>
          <!-- 备注-可修改（已审核、已拒绝） -->
          <a-row v-if="infoData.audit_status == 2 || infoData.audit_status == 3">
            <a-col :span="24">
              <a-form-model-item :labelCol="{ span: 2 }" :wrapperCol="{ span: 22 }" prop label="审核备注">
                <div v-if="isEidtRemarkShow" class="flex">
                  <a-input v-model="formData.audit_remarks" style="margin-right:30px;"></a-input>
                  <a-button @click="handlerEdit">保存</a-button>
                </div>
                <div v-else>
                  <span>{{formData.audit_remarks}}</span>
                  <a-button type="link" @click="isEidtRemarkShow = true">修改备注</a-button>
                </div>
              </a-form-model-item>
            </a-col>
          </a-row>

        </a-form-model>
      </div>
    </a-modal>

    <a-modal
      class="preview-class"
      title="退款图片"
      width="50%"
      :visible="previewVisible"
      :footer="null"
      @cancel="previewVisible = false"
    >
      <img style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script>
import { getRefundDetail, updateRefund } from "@/api/refund.js"
import { formatGoodsType } from "@/utils/type.js"

const ORDER_TYPE = {
  1: "自提",
  2: "配送",
  3: "快递",
}

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    id: {
      type: [String, Number],
    },
    orderNo: {
      type: [String, Number],
    },
    
  },
  data() {
    return {
      form: {},
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      previewVisible: false,
      previewImage: "",
      columns: [
        {
          title: "产品属性",
          dataIndex: "goods_type",
          align: "center",
          width: 90,
          slots: {
            customRender: "type",
          },
          slotsType: "format",
          slotsFunc: (val) => formatGoodsType(val),
        },
        {
          title: "商品名称",
          dataIndex: "goods_name",
          align: "center",
          width: "40%",
        },
        {
          title: "口味",
          dataIndex: "flavor_name",
          align: "center",
          width: "30%",
        },
        {
          title: "尺寸",
          dataIndex: "specification_name",
          align: "center",
          width: "30%",
        },
        {
          title: "数量",
          dataIndex: "number",
          align: "center",
          width: "80px",
        },
        {
          title: "商品总价",
          dataIndex: "total_price",
          align: "center",
          width: "80px",
        },
        {
          title: "支付金额",
          dataIndex: "total_deal_price",
          align: "center",
          width: "80px",
        },
        {
          title: "是否退款",
          dataIndex: "is_refund",
          align: "center",
          width: "80px",
          slots: {
            customRender: "is_refund",
          },
          slotsType: "format",
          slotsFunc: (val) => {
            return val==2?"退款":"——"
          },
        },
      ],
      columns_drink: [
        {
          title: "商品名称",
          dataIndex: "goods_name",
          align: "center",
          width: "40%",
        },
        {
          title: "属性",
          dataIndex: "show_desc",
          align: "center",
          width: "60%",
        },
        {
          title: "数量",
          dataIndex: "number",
          align: "center",
          width: "80px",
        },
        {
          title: "商品总价",
          dataIndex: "goods_price",
          align: "center",
          width: "80px",
        },
        {
          title: "支付金额",
          dataIndex: "order_price",
          align: "center",
          width: "80px",
        },
        {
          title: "保温袋",
          dataIndex: "is_pack",
          align: "center",
          width: 80,
          slots: { customRender: "is_pack" },
          slotsType: "all",
          slotsFunc: (val) => {
            return val.is_pack==2 ? val.pack_amount : "无"
          },
        },
      ],

      imgList: [],
      infoData: {},
      formData: {
        id: this.id,
        audit_pick_up_order_refund_amount: 0,
        audit_delivery_order_refund_amount: 0,
        audit_express_order_refund_amount: 0,
        audit_remarks: "",
      },
      isEidtRemarkShow: false
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    async initData() {
      getRefundDetail({ id: this.id }).then((res) => {
        if (res.code === 0) {
          const infoData = res.data

          let pack_count = 0
          infoData.suborder_list.map((order) => {
            order.goods_list.map(sku=>{
              if(sku.is_pack==2){
                pack_count += sku.number
              }
            })
          })
          let pack_price = 0
          if(pack_count){
            const pack_amount = Math.round(Number(infoData.pack_amount)*100) / 100
            pack_price = Math.round(Number(pack_amount/pack_count)*100) / 100
          }

          infoData.suborder_list.map((order) => {
            order.order_type_name = ORDER_TYPE[order.suborder_type]
            order.order_type = order.suborder_type
            order.isDrink = order.goods_list.some(sku=>sku.goods_type==4)
            if(order.isDrink){
              const merge_goods_list = []
              order.goods_list.map(sku=>{
                sku.pack_amount = pack_price * sku.number

                const merge_sku = merge_goods_list.find(el=>{
                  return el.sku_id == sku.sku_id
                        && el.drink_temperature_name == sku.drink_temperature_name
                        && el.drink_sugar_name == sku.drink_sugar_name
                        && el.drink_straw_name == sku.drink_straw_name
                })
                if(merge_sku){
                  sku.show_desc = merge_sku.show_desc
                  merge_sku.number = merge_sku.number + sku.number
                  merge_sku.goods_price = Number(merge_sku.goods_price) + Number(sku.goods_price)
                  merge_sku.order_price = Number(merge_sku.order_price) + Number(sku.order_price)
                  merge_sku.pack_amount = pack_price * merge_sku.number
                }else{
                  const tmp = []
                  // if(sku.flavor_name)            tmp.push(sku.flavor_name)
                  if(sku.specification_name)     tmp.push(sku.specification_name)
                  if(sku.drink_temperature_name) tmp.push(sku.drink_temperature_name)
                  if(sku.drink_sugar_name)       tmp.push(sku.drink_sugar_name)
                  if(sku.drink_straw_name)       tmp.push(sku.drink_straw_name)
                  sku.show_desc = tmp.join("；")
                  merge_goods_list.push({ ...sku })
                }
              })
              order.merge_goods_list = merge_goods_list
            }
          })

          this.infoData = infoData
          
          let audit_amount = 0
          let freight_amount = 0

          res.data.suborder_list.map((el) => {
            el.order_type_name = ORDER_TYPE[el.order_type]
            //  (1-自提，2-配送，3-快递)
            if (el.order_type === 1) {
              this.formData.audit_pick_up_order_refund_amount =
                el.audit_refund_amount
            } else if (el.order_type === 2) {
              this.formData.audit_delivery_order_refund_amount =
                el.audit_refund_amount
            } else if (el.order_type === 3) {
              this.formData.audit_express_order_refund_amount =
                el.audit_refund_amount
            }

            el.freight_amount = Number(el.apply_refund_freight_amount) || 0
            freight_amount += el.freight_amount
            audit_amount += parseFloat(el.audit_refund_amount) || 0
          })

          this.infoData.audit_refund_amount = audit_amount
          this.infoData.apply_refund_freight_amount = freight_amount
          this.imgList = res.data.attached_image_list
          this.formData.audit_remarks = this.infoData.audit_remarks
        }
      })
    },

    handlerEdit () {
      const params = {
        id: this.formData.id,
        audit_remarks: this.formData.audit_remarks,
      }
      updateRefund(params).then(res => {
        if (res.code === 0) {
          this.$message.success('修改成功！')
          // this.$parent.initData()
          // this.$emit('update:show', false)
          this.isEidtRemarkShow = false
        }
      })
    },

    // 查看订单详情
    handlerOrderDetail() {
      const newPage = this.$router.resolve({
        name: "order-detail",
        query: { 
          order_no: this.orderNo,
        },
      });
      window.open(newPage.href, "_blank");
    },

    handlerBigImg(e) {
      this.previewImage = e.image_url
      this.previewVisible = true
    },

    formatStatus(val){
      // 1-未审核，2-审核通过，3-拒绝，5-已退款
      if(val == 1) return "待审核"
      if(val == 2) return "已审核待退款"
      if(val == 3) return "申请被拒绝"
      if(val == 5) return "已完成退款"
      return val
    },

  },
}
</script>

<style lang="less" scoped>
.audit_amount_input {
  max-width: 140px !important;
  .ant-input-number {
    width: 100px;
    max-width: 120px;
  }
  .plus {
    margin: 0 5px;
  }
}
.footer {
  margin: 20px 0;
  .img-class {
    width: 100px;
    height: 75px;
    // object-fit: cover;
    margin-right: 10px;
    margin-top: 10px;
    border-radius: 5px;
    cursor: pointer;
  }
}

.ant-form-item {
  margin-bottom: 0;
}
</style>